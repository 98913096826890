import { AxiosError } from 'axios';

import { RequestService } from '~/services/Request';
import { MASTAZ_SUBMIT_SOLUTION } from '~/constants';

import { MastazSolutionFormValues } from '../../types';

const useSubmit = (
  onSubmitSucceed: () => void,
  onSubmitFailed: (error: AxiosError) => void,
) => {
  const submit = (data: MastazSolutionFormValues) => {
    const { file, email, fullName, phone } = data;
    const body = new FormData();

    if (file) {
      body.append('submission', file, file.name);
    }
    body.append('email', email);
    body.append('full_name', fullName);
    body.append('phone', phone);

    return RequestService.client
      .post(MASTAZ_SUBMIT_SOLUTION, body)
      .then(() => {
        onSubmitSucceed();
      })
      .catch((error: AxiosError) => {
        onSubmitFailed(error);
      });
  };

  return {
    submit,
  };
};

export default useSubmit;
