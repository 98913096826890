import { MastazSolutionDTO } from '~/pages/mastaz-solution/types';
import metadata from './metadata';
import envelop from './assets/envelope-big.svg';
import SwingIntern from './assets/swing-intern.svg';

const mastazSolution: MastazSolutionDTO = {
  metadata: metadata,
  pageTitle: 'Submit your MaStaż task solution',
  title: 'Submit your MaStaż task solution',
  successTitle: 'Your solution has been sent',
  icon: SwingIntern,
  text:
    'If you have any other questions, e-mail us at <a href="mailto:talent@swing.dev">talent@swing.dev</a>',
  successIcon: envelop,
  submit: 'SUBMIT SOLUTION',
};

export default mastazSolution;
