import React, { useState } from 'react';

import Layout from '~/components/Layout';
import Hero from './components/Hero';
import mastazSolution from '~/data/mastaz-solution';
import Form from './components/Form';

import styles from './MastazSolution.module.scss';

const EstimateProject: React.FC = () => {
  const [success, setSuccess] = useState<boolean>(false);

  const {
    metadata,
    successTitle,
    text,
    successIcon,
    title,
    icon,
  } = mastazSolution;

  return (
    <Layout
      pageTitle={metadata.pageTitle}
      metaDescription={metadata.metaDescription}
      invertNavigation
    >
      <section className={styles.root}>
        {success ? (
          <div className={styles.form}>
            <Hero
              title={successTitle}
              text={text}
              icon={successIcon}
              isSuccess
            />
          </div>
        ) : (
          <>
            <div className={styles.doodle} />
            <div className={styles.form}>
              <Hero title={title} text={text} icon={icon} />
              <Form
                estimateData={mastazSolution}
                onSuccess={() => {
                  setSuccess(true);
                  // Since success screen & form are part of the same page,
                  // we need to scroll view to the top on succeessful sent.
                  window.scrollTo(0, 0);
                }}
              />
            </div>
            <div className={styles.doodleRight} />
          </>
        )}
      </section>
    </Layout>
  );
};

export default EstimateProject;
