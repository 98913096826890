import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone, { DropzoneState, FileRejection } from 'react-dropzone';

import { ReactComponent as FileIcon } from './assets/checked-document.svg';
import { ReactComponent as CloudIcon } from './assets/cloud.svg';
import { ReactComponent as RemoveIcon } from './assets/remove.svg';

import styles from './UploadDocuments.module.scss';
import { useFormContext } from 'react-hook-form';
import { MastazSolutionFormValues } from '../../types';
import { ReactComponent as ErrorIcon } from '~/assets/icons/error-red.svg';

const MAX_FILE_BYTES_SIZE = 10000000;

const constructTooBigFilesMessage = (files: File[]) => {
  const baseMessage = 'Maximum file size is 10MB.';
  const filesNames = files.map((file) => file.name).join(', ');
  const word = files.length === 1 ? 'was' : 'were';

  return `${baseMessage} ${filesNames} ${word} too big`;
};

const FileItem: React.FC<{
  name: string;
  onClickDelete: () => void;
}> = ({ name, onClickDelete }) => {
  return (
    <div className={styles.file}>
      <FileIcon className={styles.fileIcon} />
      <div className={styles.name}>{name}</div>
      <button className={styles.remove} onClick={onClickDelete} type='button'>
        <RemoveIcon />
      </button>
    </div>
  );
};

const UploadDocumentsComponent: React.FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { setValue, register, watch } = useFormContext<
    MastazSolutionFormValues
  >();

  useEffect(() => {
    register({ name: 'file' }, { required: true });
  }, [register]);

  const file = watch('file');

  const renderDropzoneBox = ({
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  }: DropzoneState) => {
    const classes = classNames(styles.box, {
      [styles.active]: isDragActive,
      [styles.failure]: !!errorMessage,
    });

    return (
      <section className={classes} {...getRootProps()}>
        {children}
        {/* Rule `react-a11y-input-elements` incorrectly requiring placeholder
          https://github.com/microsoft/tslint-microsoft-contrib/issues/749 */}
        {/* tslint:disable react-a11y-input-elements */}
        <input {...getInputProps()} />
        {/* tslint:enable react-a11y-input-elements */}
        <CloudIcon className={styles.cloudIcon} />
        <span className={styles.link}>
          <span className={styles.text}>Drop file here or </span>
          <button className={styles.browse} onClick={open} type='button'>
            browse
          </button>
        </span>
        {errorMessage ? (
          <span className={styles.error}>
            {' '}
            <ErrorIcon /> {errorMessage}
          </span>
        ) : null}
      </section>
    );
  };

  const addFile = useCallback(
    (acceptedFiles: File[], rejections: FileRejection[]) => {
      const rejectedFiles = rejections.map((r) => r.file);
      if (rejectedFiles.length > 0) {
        setErrorMessage(constructTooBigFilesMessage(rejectedFiles));
      } else if (errorMessage) {
        setErrorMessage(null);
      }
      setValue('file', acceptedFiles[0]);
    },
    [errorMessage, setErrorMessage, setValue],
  );

  const deleteFile = useCallback(() => {
    setValue('file', null);
  }, [setValue]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {!file && (
          <div className={styles.dropzone}>
            <Dropzone
              maxSize={MAX_FILE_BYTES_SIZE}
              onDrop={addFile}
              accept='.zip'
            >
              {renderDropzoneBox}
            </Dropzone>
          </div>
        )}

        {file && (
          <div className={styles.list}>
            <FileItem name={file.name} onClickDelete={deleteFile} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDocumentsComponent;
